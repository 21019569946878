body {
    background: #171B37 url('../src/assets/landing/background1.png') no-repeat left center !important;
    background-size: cover !important;
    overflow-y: scroll;
    overflow-x: hidden !important;
    
}

.textCenter{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding:0px;
}
.backgroundContainer {}
.entireDiv {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
}
.mobileOneContainer {
    max-width: 70%;
    display: inline-block;
}
.mobileOneContainerFixed {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.oneDiv {
    width: 100%;
    height: 100vh;
    padding: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3000;
    position: relative;
}
.iconsContainer {
    position: absolute;
    width: 200%;
    background: transparent url('../src/assets/landing/icons.png') repeat-x center center !important;
    background-size: contain !important;
    height: 20vh;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.2;
}
.mediumFont {
    font-size: 60px;
    font-weight: bold;
    text-align: left;
}
.hugeFont {
    font-size: 100px;
    font-weight: bold;
}
.hugeFontSemi{
    font-size: 70px;
    font-weight: bold;
    text-transform: uppercase;

}
.midCol {
    padding: 10% 5%;
    border-right: 5px #BC4FFF solid;
}
.subFont {
    font-size: 20px;
    font-weight: bold;
}
.section-line {
    border: 2px #fff solid;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    position: relative;
}
.qrBox {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.section-line-container {
    width: 100%;
    height: 100%;
    text-align: center;
    position: fixed;
    padding: 40px;
    z-index: -1;
}
.section-container {
    width: 100%;
    text-align: center;
    position: fixed;
    margin: 20px;
}
.gradBox {
    background: rgb(153, 109, 155);
    background: linear-gradient(90deg, rgba(153, 109, 155, 0.7721682422969187) 0%, rgba(73, 73, 121, 0.7889749649859944) 55%, rgba(50, 58, 110, 0.7637648809523809) 100%);
    padding: 20px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}
.input {
    border: 0px;
    background: rgba(255, 255, 255, 0.2);
    padding: 25px 15px;
    color: #222;
    border-radius: 5px;
    font-size: 20px;
    margin: 10px 0px;
}
.backgroundItem {
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 2000;
    max-width: 20%;
}

.telegram{
    background:#30A4DA !important;
    border:2px #30A4DA solid !important;
    color:#fff;
    font-size: 13px;
    height: 45px;
    border-radius:30px;
    width:200px;
    text-transform: uppercase;
}

#emrMonoContainer{
    font-size: 150px;
}

.emrMono{
    max-height: 15vh;
    margin-right: 30px;
}

.arzi{
    width:70%;
    margin-top: -150;

}
.arziContainer{
    max-width: 70%;
    z-index: 20000;
}

.iphone{
    position: absolute;
    width: 150%;
    left: 0px;
    top: -50px

}

.showMobile{
    display: none;
}

.showDesk{
    display: block;
}

.descarca{
    padding-top: 50;
    border-top:5px #BC4FFF solid;
    margin-top: 50;
    font-size: 25;

}

.coupon{
    background-color:#161730;
            padding:8px 20px;
            border-radius:30px;
            color:#fff;
            width:250px;
            display:inline-block;
            margin-top:10px;
            text-align:left;
            cursor:pointer;
            transition: 0.5s;
}

.coupon:hover{
    background-color:#765D93;
    color:#fff;
}
.timerContainer{
display: flex;
padding:20px;
flex-direction: row;
justify-content: space-around;
align-items: center;
}
.timer{
    background:transparent url('../src/assets/landing/timerback.png') no-repeat center center;
    width: 50%;
    height:21vh;
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    font-size: 100px;
    color:#fff;
}

.userTimer .timer{
    width:11% !important;
    height:21vh;
}


@media (max-width: 600px) {

    body {
        background: #171B37 url('../src/assets/landing/backgroundmobile.png') no-repeat center center !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden !important;
        
    }
    
    #emrMonoContainer{
        font-size: 20px;
    }

    .timerContainer{
        display: flex;
        padding:20px;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        }
        .timer{
            background:transparent url('../src/assets/landing/timerback.png') no-repeat center center;
            width: 49%;
            height:8vh;
            background-size: cover;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-weight: bold;
            font-size: 35px;
            color:#fff;
        }
        

        .userTimer .timer {
            width: 11% !important;
            height: 5vh;
        }

    .emrMono{
        max-height: 60px;
        margin-right: 10px;
    }
    
    .subFont {
        font-size: 13px;
        font-weight: normal;
    }

    
    .mediumFont {
        font-size: 20px;
        font-weight: bold;
    }

    .hugeFont {
        font-size: 30px;
        font-weight: bold;
    }

    .arzi{
        width:90%;
        margin-top:0;
    }
    
    .arziContainer{
        max-width: 90%;
    }
    
    .section-line {
        border: 0px #fff solid;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        position: relative;
        
    }
    .section-line-container {
        width: 100%;
        height: 100%;
        text-align: center;
        position: fixed;
        padding: 20px;
        z-index: -1;
        
    }

    .hugeFontSemi{
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    
    }
    
    .iphone{
        position: relative;
        width: 120%;
        left: auto;
        top: auto;
    
    }

    .oneDiv {
        width: 100%;
        height: auto;
        min-height: 100vh;
        padding: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 3000;
        position: relative;
        overflow: hidden;
    
    }
    .showDesk{
        display: none;
    }

    .showMobile{
        display: block;
    }
    .descarca{

        padding-top: 20px;
        border:0px #BC4FFF solid;
        margin-top: 0px;
        font-size: 20px;
        text-align: center;
        font-weight: bold;


    }

    .videoBox{
        background:transparent url('../src/assets/landing/videomobile.png') no-repeat left top;
        background-size: cover;
    }
}