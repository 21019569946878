/* Partners.css */

.partnersSection {
    /* background-color: rgba(14, 14, 56, 0.3); */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 30px 0px;
    min-height: 20vh;

    position: relative;  
}
.partnersSection > img{
    position: absolute;
    top: -200px;
    width: 100%;
}

.partnersRow {
    max-width: 1340px;
    width: 90%;
}

.partnersLogoContainer {
    display: flex;
    align-items: center; /* Vertically centers the logo */
    justify-content: center; /* Horizontally centers the logo */
    height: 100%; /* Full height of the slide */
}

.partnersLogo {
    /* max-width: 200px; */
    display: block;
    margin: 0 auto;
    transition: all 0.3s ease; /* Adjust transition for smoother effect */
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background:url("../assets/partners/ellipse_simple.png");
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: cover;
}

.isHovered{
    width: 270px !important;
    height: 270px !important;
}
.partnersLogo > img{
    width: 100px;
    height: 100px;
}
.partnersLogo:hover {
    background:url("../assets/partners/ellipse_hovered.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    /* background-position: center !important; */
    width: 200px !important;
    height: 200px !important;
}