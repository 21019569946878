
  .fullpage{
    page-break-after: always;
    text-align: center;    
  }
  

  .heading{
    font-size: 11px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 10px;
  }

  .contractTable {
    width:100%;
  }

  .contractTable tr td{
    text-align: left;
    padding-top:10px;
    vertical-align: top;
    
  }
  .contractTable tr td:nth-child(1){
    width:40px;
    text-align: left;
    font-weight: bold;
  }
  .contractContainer{
    width: 800px;
    display: inline-block;
  }

  .contractTableInner tr td:nth-child(1){
    width:30px;
    text-align: left;
    font-weight: bold;

  }
  .contractTableInner tr td:nth-child(2){
    width:120px;
    text-align: left;

  }
  .contractTableInner tr td:nth-child(3){
    width:20px;
    text-align: left;
    font-weight: bold;

  }


  .generator{
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0px;
    left:0px;
    background:rgba(0,0,0,0.8);
    z-index: 200;
  }