.row {
  margin: 0px;
}

.row90 {
  width: 90%;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 36px 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 20;
  position: sticky;
  top: 0;
}

.blurHeader {
  background-color: #162b8621;
  backdrop-filter: blur(15px);
}

.privacyHead {
  height: 100% !important;
}

.modalContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 200000;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  overflow-y: auto;
  padding-top: 50px;
}

.modalBox {
  width: 50%;
  padding: 40px 25px;
  background: #fff;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #459eb1;
  padding: 5px;
  padding-top: 7px;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}

.closeButton:hover {
  color: #000;
}

.mPicture {
  width: 100%;
  height: 70vh;
}

.logoMenu {
  width: 200px;
}

.logoTop {
  width: 80%;
}

.header {
  width: 90%;
}

.headSecond {
  text-align: left;
}

.menu {
  text-align: right;
}

.menu ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.menu ul li {
  display: inline-block;
}

.menu ul a a {
  padding: 0px;
}

.menu ul a {
  display: block;
  padding: 10px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  transition: 0.3s;
}

.menu ul li .a {
  display: block;
  padding: 10px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  transition: 0.3s;
}

.menu ul a:hover {
  color: #78f7df;
}

.box-under-green {
  text-align: center;
  width: auto;
  margin: auto;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
  background: #2c4b9cbd;
  background-clip: padding-box;
  /* !importanté */
  border: solid 2px transparent;
  /* !importanté */
  border-radius: 10px;
  font-size: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.div-green{
   
  text-align: center;
  width: auto;
  margin: auto;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
  background: #2c4b9cff;
  background-clip: padding-box;
  /* !importanté */
  border: solid 2px transparent;
  /* !importanté */
  border-radius: 10px;
  font-size: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
  border:2px #1ad182 solid;
  border-bottom: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
}

.div-blur{
  filter: blur(25px);
    background: #1C3597;
    position: absolute;
    height: 160px;
    width: 150%;
    bottom: -60px;
    left: -25%;
}

.btn-green {
  text-align: center;
  width: auto;
  margin: auto;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
  background: #2c4b9cbd;
  background-clip: padding-box;
  /* !importanté */
  border: solid 2px transparent;
  /* !importanté */
  border-radius: 10px;
  font-size: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
}

.earlyAccess {
  padding: 10px 0px !important;
}

.earlyAccess .formElement {
  padding: 5px 0px !important;
}

.input-green {
  text-align: center;
  width: auto;
  margin: auto;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
  background: #2c4b9cbd;
  background-clip: padding-box;
  /* !importanté */
  border: solid 2px transparent;
  /* !importanté */
  border-radius: 10px;
  font-size: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  display: block;
  text-align: left;
  color: #fff;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.btn-green:hover {
  background: transparent;
}

.btn-green-wrapper {
  border-radius: 10px;

  background: linear-gradient(to right, #17a6fb, #00ce79);

  display: inline-block;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 400px;
  overflow: hidden;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
}

.headRectangle {
  height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;

}


.contactForm{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;


}

.headRectangle .text,.contactForm .text {
  font-size: 18px;
  max-width: 646px;
  width: 100%;
  margin-block: 20px;
}

.headFirst {
  text-align: right;
  padding-right: 50px;
}

.headFirstInner {
  text-align: center;
  display: inline-block;
  width: 80%;
}

.headRectangle .row,.contactForm .row {
  width: 90%;
}

.green-heading {
  font-size: 40px;
  color: #41faa4;
}

.green-subheading {
  font-size: 20px;
  color: #41faa4;
}

.green-color {
  color: #41faa4;
}

.text {
  margin: 10px 0px;
  font-size: 15px;
}

.lineHeading {
  padding: 10px;
  font-size: 15px;
  border-bottom: 1px #fff solid;
  text-transform: capitalize;
  font-weight: normal;
  width: 100%;
  color: #fff;
  text-align: left;
}

.lineHeadingIntro {
  padding-bottom: 10px;
  font-size: 15px;
  text-transform: capitalize;
  width: 100%;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.timerContainer {
  width: 100% !important;
}

.timerContainer .col-sm-3 {
  text-align: center;
}

.timerContainer .number {
  font-size: 40px;
  font-weight: normal;
}

.earlyHead .number {
  font-size: 30px !important;
  font-weight: normal;
}

.timerContainer .label {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: normal;
}

.timerContainer .col-sm-12 {
  padding: 40px 0px;
}

.timerContainer .btn-green-wrapper {
  width: 50%;
}

.timerContainer .btn-green {
  background: transparent;
  width: 100%;
}

.blueSubHeading {
  font-size: 18px;
  color: #00CDF5;
  margin: 10px 0px;
}

.emoryaBox {
  max-width: 130px;
  width: 100%;
}

.emoryaBox span {
  white-space: pre-line;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.emoryaBox div {
  top: 0;
  width: 118px !important;
  height: 118px !important;
  right: 0;
}

.whiteHeading {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0px;
}

.row {
  max-width: 1340px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;
  position: relative;
}

.burnRow {
  min-height: 1000px;
  background: transparent url("./assets/globe.png") scroll no-repeat left 20%;
}

.burnSection {
  height: 80vh;
}

.burnImages {
  margin-left: -20%;
  width: 100%;
  position: relative;
}

.globe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 200;
}

.sports {
  text-align: center;
}

.whiteHeadingSmall {
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
}

.linesContainer {
  position: relative;
  background: transparent url("./assets/lines.png") scroll no-repeat center center;
  height: 390px;
  background-size: contain;
  margin-top: 100px;
  display: block;
  display: none;
}

.linesContainerMobile {
  display: none;
}

.lines .col-sm-4 {
  text-align: center;
  padding: 0px;
}

.lineBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 400px;

  font-size: 15px;
  line-height: 25px;

  padding-top: 15%;
  color: rgba(255, 255, 255, 0.7);
}

.gradColor {
  background: -webkit-linear-gradient(45deg, #17a6fb, #00ce79 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradline {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0px;
}

.whitePaperBox {
  text-align: center;
  padding: 40px 0px;
}

.whitePaperBox .btn-green {
  background: transparent;
}

.telegram {
  font-size: 13px;
  font-weight: bold;
}

.tokenBox {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tokenBox .row {
  width: 100%;
}

.divPad {
  text-align: left;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 30%;
  font-size: 15px;
  font-weight: bold;
}

.divPad .fa-circle {
  font-size: 10px;
  vertical-align: middle;
}

.tba {
  padding-left: 20px;
  font-size: 10px;
  margin: 0px 0px;
  font-weight: normal;
}

.divPadRight .divPad {
  text-align: right;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 30%;
  padding-left: 0px;
  font-size: 15px;
  font-weight: bold;
}

.divPadRight .divPad .tba {
  padding-left: 0px;
  padding-right: 25px;
  font-size: 10px;
  margin: 0px 0px;
  font-weight: normal;
}

.tokenSmallBox {
  padding: 20px 0px;
}

.tokenSmallBox .tba {
  padding-left: 25px;
  font-size: 12px;
}

.dBoxes {
  background-size: contain !important;
  height: 200px;
  text-align: center;
  padding-top: 65px;
  font-weight: bold;
  font-size: 15px;
}

.dTax {
  background: transparent url("./assets/tax.png") scroll no-repeat center center;
}

.dBurnTax {
  background: transparent url("./assets/burntax.png") scroll no-repeat center center;
}

.dBuyBack {
  background: transparent url("./assets/buyback.png") scroll no-repeat center center;
}

.dLine {
  height: 1px;
  width: 100%;
  background-color: rgb(91 101 179);
  margin-top: 0px;
}

.dBoxeslower .dBoxes {
  padding-top: 110px !important;
}

.dBoxesContainer {
  text-align: center;
}

.dBoxesContainer .text {
  padding-top: 40%;
  font-size: 13px;
}

.dBoxeslower .text {
  padding-top: 15px !important;
}

.roadMap {
  margin-top: 50px;
}

.roadMap .gradColor {
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0px;
}

.roadBox {
  width: 20%;
  padding: 20px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  vertical-align: top;
}

.dApps {
  font-size: 14px;
}

.dAppsLine {
  padding: 10px 0px;
}

.appIcons {
  display: inline-block;
  margin-left: 20px;
}

.appIcons a {
  display: inline-block;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 20px;
}

.teamContainer .col-sm-3 {
  padding: 20px;
}

.teamBox {
  background: transparent url("./assets/team-background.png") scroll no-repeat center center;
  height: 400px;
  background-size: contain;
  margin: 15px;
  display: inline-block;
  width: 17%;
  padding: 20px;
  padding-top: 70px;
  vertical-align: top;
  font-size: 14px;
}

.teamBox a {
  display: inline-block;
  padding: 10px;
  padding-top: 0px;
  margin-top: 10px;
  text-align: center;
}

.teamBox i {
  color: #7cf5a5;
  margin-top: 10px;
}

.blueText {
  color: #17a6fb;
}

.teamBox .blueSubHeading {
  font-size: 12px;
  width: 80%;
  display: inline-block;
  font-weight: normal;
}

.teamBox strong {
  font-size: 13px;
}

.ambsBox {
  background: transparent url("./assets/ambs-back.png") scroll no-repeat center center;
  height: 450px;
  background-size: contain;
  margin: 15px;
  display: inline-block;
  width: 20%;
  padding: 20px;
  padding-top: 3%;

  font-size: 14px;
  vertical-align: top;
}

.ambsBox .text {
  font-size: 12px;
  width: 80%;
  display: inline-block;
  margin-top: -5px;
}

.ambsBox a {
  display: inline-block;
  padding: 10px;
  text-align: center;
}

.ambsBox i {
  color: #7cf5a5;
  margin-top: 10px;
}

.formElement {
  padding: 20px 0px;
  text-align: left;
}

.formElement label {
  font-size: 12px;
  color: #ccc;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 20px;
}

.error {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  color: red;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
}

.input {
  border: 0px;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  background: transparent;
  display: block;
  width: 100%;
  outline: none;
  padding-top: 0px;
  padding-left: 0;
}

.inputLine {
  border-radius: 10px;
  background: linear-gradient(to right, #00ce79, #17a6fb);
  height: 2px;
}

.formButton {
  text-align: center;
}

.formButton .btn-green {
  background: transparent;
  width: 200px;
}

.reachSmallBox {
  padding: 20px 0px;
}

.reachSmallBox .btn-green-wrapper {
  width: 52px;
  height: 52px;
  text-align: center;
  padding-top: 1px;
}

.reachSmallBox .btn-green {
  width: 50px;
  height: 50px;
  padding: 10px;
  padding-top: 8px;
  background: #162b86;
  display: inline-block;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.4);
  transition: 0.3s;
}

.reachSmallBox .btn-green:hover {
  color: rgba(255, 255, 255, 1);
}

.robot img {
  margin-top: -150px;
}

.blogBox {
  background: transparent url("./assets/blog-bck.png") scroll no-repeat center center;
  height: 350px;
  background-size: contain;
  margin: 15px;
  display: inline-block;
  width: 20%;
  padding: 15px;
  font-size: 14px;
  vertical-align: top;
  text-align: left;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s;
}

.TweetBox {
  height: auto;
  margin: 15px;
  display: inline-block;
  width: 20%;
  vertical-align: top;
  text-align: left;
  cursor: pointer;
}

.blogBox img {
  width: 100%;
}

.blogBox .blueSubHeading {
  font-size: 12px;
  font-weight: normal;
}

.blogBox:hover {
  opacity: 1;
}

.footer {
  background: transparent url("./assets/footer.png") scroll no-repeat center center;
  background-size: cover;
  padding: 40px 0px;
  position: relative;
  overflow: hidden;
}

footer .logoMenu {
  object-fit: contain;
}

.lightHeading {
  font-weight: bold;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.3);
}

button {
  outline: none !important;
}

.btn-group {
  border-radius: 0px;
}

.btn-group .btn-green {
  border-radius: 0px;
}

.footer {
  line-height: 40px;
  min-height: 60vh;
}

.footer a {
  display: block;
  color: #fff;
}

.footerBottom {
  border-top: 1px #101f62 solid;
  padding: 10px 0px 20px 0px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 11px;
  line-height: 20px;
}

.footerIcons a {
  display: inline-block !important;
  padding: 10px;
  text-align: center;
}

.whatIsFirst {
  text-align: left;
}

.whatIsSecond {
  text-align: center;
  padding-top: 0px;
}

.whatIsSecond img {
  width: 90%;
}

.burnTextContainer {
  text-align: right;
}

.burnTextContainer .text {
  color: #A2AACF;
}

.burnText {
  text-align: right;
}

.sportText {
  text-align: center;
  width: 100%;
}

.preSale {
  margin-top: 78px;
}

.tokenDistHead {
  margin-top: 131px;
  margin-bottom: 100px;
  text-align: center;
}

.feeBreakDown {
  padding-top: 110px;
}

.roadText {
  width: 50%;
  display: inline-block;
}

.road-mobile {
  display: none;
}

.dappsImage {
  width: 80%;
  margin-top: -100px;
}

.dAppstext {
  width: 50%;
  text-align: left;
}

.daapsHeading {
  width: 50%;
  display: inline-block;
}


.downLoadSection {
  text-align: left;
}

.TeamSection {
  margin-top: 90px;
}

.sports {
  margin-top: 0;
}

.TeamHeadingContainer {
  width: 50%;
  display: inline-block;
  margin-top: 40px;
}

.reachOut {
  margin-top: 100px;
  text-align: left;
}

.showOnMobile {
  display: none;
}

.showOnDesktop {
  display: block;
}

Link {
  padding: 0px;
}

.mobileTeam {
  display: none;
}

#footerStars {
  opacity: 0.5 !important;
}

.dotCanvas {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.8;
}

.menu li {
  position: relative;
}

.menu li .submenu {
  position: absolute;
  top: 30px;
  left: 0px;
  width: auto;
  padding: 10px;
  border-radius: 10px;
  background: #2c499c;
  z-index: 200;
  display: none;
  transition: 0.5s;
  text-align: left;
}

.menu li:hover .submenu {
  display: block;
}

.submenu a {
  display: block;
  padding: 5px 10px !important;
}

.mobileHeader {
  display: none;
}

.barsMenu {
  display: none;
}

.earlyHead {
  width: 40%;
  display: inline-block;
}

.earlyHeading {
  padding: 10px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: normal;
  width: 100%;
  color: #fff;
  text-align: center;
}

.mName {
  font-size: 30px;
  font-weight: bold;
}

.mTitle {
  font-size: 12px;
  text-transform: uppercase;
}

.mSocial {
  padding-top: 10px;
}

.mSocial a {
  color: #59c89e;
  font-size: 14px;
  display: inline-block;
  padding-right: 10px;
}

.mAbout {
  font-size: 14px;
  padding: 20px 0px;
}

.skillName {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.skillPercent {
  float: right;
}

.skillLine {
  width: 100%;
  height: 5px;
  background: #eee;
  position: relative;
  margin-top: 5px;
  margin-bottom: 30px;
}

.skillWidth {
  width: 50%;
  background: linear-gradient(to right, #17a6fb, #00ce79);
  height: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.modalImage img {
  width: 100%;
}

.mDataContainer {
  padding: 0px 20px;
}

.pdfPage {
  width: "100%";
  height: 100vh;
  background: transparent;
}

.pdfPage img {
  width: 100%;
}

.pdfPageFirst {
  background: transparent !important;
}

.clickIndicator {
  font-size: 12px;
  font-style: italic;
  color: #fff;
  text-align: right;
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff;
  z-index: 2000;
}

.codeHolder {
  position: absolute;
  top: 20;
  left: 20;
}

.contractHeading {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;
  width: 100%;
}

.contractHeading .row {
  width: 90%;
}

.dataTable td {
  border: 0px;
  font-size: 11px;
  border-bottom: 1px #102361 solid;
}

.dataTable th {
  border: 0px;
  font-size: 11px;
}

.companyBox {
  width: 45%;
}

.companycontent {
  border-radius: 14px;
  background-color: #f6f6f60d;
  max-width: 582px;
  backdrop-filter: blur(15px);
  width: 100%;
}

.rounds-company-0 {
  z-index: -1;
  top: -38%;
  right: 118px;
}

.rounds-company-1 {
  left: 0;
  top: -49% !important;
  z-index: -1;
}

.companycontent span {
  padding-top: 14px;
  padding-bottom: 25px;
}

.btn-company,
.btn-company button {
  max-width: 219px !important;
  width: 100%;
}

.btn-company .btn-green {
  background: #4F5FA4;
  background-clip: padding-box;
  text-transform: none;
  font-size: 14px;
  padding-inline: 0;
}


.videoBox {
  height: 50vh;
}


.btn-company a {
  color: white;
  text-decoration: none;
}

.emoryBoxsContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 18px;
}

.emoryBox-0 {
  grid-area: 1 / 1 / 2 / 2;
}

.emoryBox-1 {
  grid-area: 1 / 3 / 2 / 4;
}

.emoryBox-2 {
  grid-area: 1 / 5 / 2 / 6;
}

.emoryBox-2 div,
.emoryBox-4 div,
.emoryBox-5 div {
  left: 2px;
}

.emoryBox-1 div {
  align-items: flex-end !important;
  left: -1px;
  top: 14px;
}

.emoryBox-1 span {
  font-weight: 500;
}

.emoryBox-3 {
  grid-area: 2 / 2 / 3 / 3;
}

.emoryBox-4 {
  grid-area: 2 / 4 / 3 / 5;
}

.emoryBox-5 {
  grid-area: 3 / 3 / 4 / 4;
}

.emoryaBox {
  justify-self: center;
}

.sportsContent {
  top: 70px;
  max-width: 1261px;
  width: 100% !important;
  gap: 9%;
  padding-inline: 18px;
}

.sportsContent div {
  max-width: 313px;
  width: 100%;
}

.sportsContent .bodyText {
  color: #AFB6D5;
  padding-top: 17px;
  display: block;
}

.sportsContent .gradColor {
  font-weight: 700;
  font-size: 20px;
}

.sportsContent span {
  text-align: center;
}

.sportsContainerBoxs {
  margin-top: 130px !important;
}

.sportsMobile {
  display: none;
}

.roadmap-content span {
  text-align: center;
}

.roadmap-content .gradColor {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 8px;
}

.roadmap-body {
  font-size: 14px;
  color: #A2AACF;
}

.roadMapContainer .top-content {
  padding-bottom: 22px;
}

.roadmap-image {
  max-width: 1168px;
  width: 100%;
  align-self: center;

}

.bottomRoadMap {
  justify-content: space-around !important;
  width: 80%;
  align-self: center;
}

.companySection {
  margin-block: 200px;
}

.strong-txt {
  color: white;
  font-style: italic;
  font-size: 17px;
  font-weight: 600;
  padding-top: 27px;
  display: block;
}

.round-gradient {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 8px;
}

.tokenContent .percent {
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;

}

.tokenContent .body-txt {
  color: #FFFFFF99;
  max-width: 349px;
  width: 100%;
}

.image-chart {
  max-width: 473px;
  width: 100%;
}

.image-chart img {
  max-width: 473px;
  width: 100%;
}

.grid-boxs {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  height: max-content;
  row-gap: 26px;
  column-gap: 87px;
}

.tokenContent {
  height: max-content;
}



.roadmap-image-slide {
  max-width: 170px !important;
  width:100%;
}

.roadmap-image-png{
  max-width: 170px !important;
  width: 65%;

}

.top-slide,
.bottom-slide {
  height: 540px;
  justify-content: start;
}

.bottom-slide {
  justify-content: end;
  /* margin-top: -80%; */
}

.top-slide {
  margin-bottom: 140px;
}

.road-swiper {
  height: 1100px !important;
}

#road-map {
  max-width: 1340px;
  width: 100%;
}

#road-map .swiper {
  width: 95vw;
  width: 100%;
}

.roadMapContainer {
  display: none !important;
}

.scrolled-vertical {
  display: none;
}

@media (min-width:1350px) {
  .whatIsFirst {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .sportsContent {
    gap: 128px;
  }

}

@media (min-height: 1200px) {
  body {
    margin: 0;
  }

  .section {
    min-height: 1000px !important;
  }


}

@media (max-width:1200px) {
  .grid-boxs {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .whatisEmoryaSection {
    flex-direction: column !important;
  }

  .whatIsFirst {
    width: 100%;
    max-width: unset;
    text-align: center;
  }

  .emoryBoxsContainer {
    padding-top: 52px;
    width: 100%;
    max-width: unset;
  }

  .sportsContent .bodyText {
    font-size: 14px;
  }

  .sportsContent {
    top: 25px;
  }

  .sportsContent .gradColor {
    width: 87%;
    font-size: 14px;
  }
}

@media (max-width: 1000px) {

  .headRectangle {
    padding-top: 0 !important;
  }

  .percent {
    font-size: 18px !important;
  }

  .tokenContent .body-txt {
    font-size: 14px;
  }

  .burnRow {
    background-size: 70%;
  }

  .mDataContainer {
    padding: 0px 20px;
  }

  .modalBox {
    width: 70%;
    padding: 40px 25px;
  }

  .menu ul a a {
    padding: 0px !important;
  }

  .earlyHead {
    width: 80%;
    display: inline-block;
  }

  .barsMenu {
    display: block;
    float: right;
  }

  .menu {
    padding-top: 30px;
  }

  .menu li .submenu {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: transparent;
    z-index: 200;
    display: none;
    transition: 0.5s;
    text-align: center;
  }

  .mobileHeader {
    position: fixed;
    width: 100%;
    height: auto;
    padding: 36px 21px;
    background: #FFFFFF29;
    top: 0px;
    left: 0px;
    z-index: 20000;
    display: block;
    backdrop-filter: blur(40px);
  }

  .mobileHeader .row {
    width: 100%;
  }

  .mobileHeader .menu li {
    display: block;
    text-align: center;
  }

  .mobileHeader .menu li a {
    padding: 20px 0px;
  }

  .mobileHeader .menu li span.a {
    padding: 20px 0px;
  }

  .whatIsSecond img {
    width: 90%;
  }

  .blogBox {
    background: transparent url("./assets/blog-bck.png") scroll no-repeat center center;
    height: 350px;
    background-size: contain;
    margin: 15px;
    display: inline-block;
    width: 35%;
    padding: 15px;
    padding-top: 25px;
    font-size: 14px;
    vertical-align: top;
    text-align: left;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
  }

  .blogSection {
    padding-top: 0px !important;
  }

  .reachSmallBox {
    padding: 10px 0px;
  }

  .reachSmallBox .btn-green-wrapper {
    width: 42px;
    height: 42px;
    text-align: center;
    padding-top: 1px;
  }

  .reachSmallBox .btn-green {
    width: 40px;
    height: 40px;
    padding: 10px;
    padding-top: 8px;
    background: #162b86;
    display: inline-block;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.4);
    transition: 0.3s;
  }

  .robot img {
    margin-top: 0px;
  }

  .reachOut {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 50px;
  }

  .teamBox {
    background: transparent url("./assets/team-background.png") scroll no-repeat center center;
    height: 350px;
    background-size: contain;
    margin: 5px;
    display: inline-block;
    width: 30%;
    padding: 20px;
    padding-top: 70px;

    font-size: 12px;
    vertical-align: top;
  }

  .ambsBox {
    background: transparent url("./assets/ambs-back.png") scroll no-repeat center center;
    height: 400px;
    background-size: contain;
    margin: 15px;
    display: inline-block;
    width: 35%;
    padding: 20px;
    padding-top: 3%;

    font-size: 12px;
    vertical-align: top;
  }

  .ambsBox .text {
    font-size: 11px;
    width: 80%;
    display: inline-block;
    margin-top: 0px;
  }

  .TeamHeadingContainer {
    width: 80%;
    display: inline-block;
  }

  .TeamSection {
    margin-top: 0px;
    padding-top: 0px !important;
  }

  .appIcons {
    display: block;
    margin-left: 0px;
    margin-top: 20px;
  }

  .downLoadSection {
    text-align: center;
  }

  .tokenBox {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dappsSection {
    margin-top: 0;
    padding-top: 0px !important;
  }

  .daapsHeading {
    width: 80%;
    display: inline-block;
  }

  .dAppstext {
    width: 100%;
    text-align: center;
  }

  .dappsImage {
    width: 100%;
    margin-top: 0px;
  }

  .roadText {
    width: 80%;
    display: inline-block;
  }

  .dBoxesContainer .text {
    padding-top: 20%;
    font-size: 11px;
  }

  .dBoxeslower .text {
    padding-top: 5% !important;
  }

  .dBoxes {
    background-size: contain !important;
    height: 190px;
    text-align: center;
    padding-top: 7%;
    font-weight: bold;
    font-size: 13px;
  }

  .feeBreakDown {
    padding-top: 0px !important;
  }

  .divPad {
    padding-left: 0px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;

    font-size: 13px;
    font-weight: bold;
  }

  .preSale {
    margin-top: 0px;
    padding: 0px !important;
  }

  .tokenDistHead {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
  }

  .divPadRight .divPad {
    text-align: right;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 13px;
    font-weight: bold;
  }

  .lineBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;

    font-size: 11px;
    line-height: 15px;

    padding-top: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  .gradline {
    font-weight: bold;
    font-size: 14px;
    margin: 20px 0px;
  }

  .linesContainer {
    position: relative;
    background: transparent url("./assets/lines.png") scroll no-repeat center top;
    height: auto;
    background-size: contain;
    margin-top: 30px;
    display: none;
  }

  .linesContainerMobile {
    display: none;
  }

  .deskmenu {
    display: none;
  }

  .row90 {
    width: 90%;
  }

  .headRectangle .row {
    width: 90%;
  }

  .headFirst {
    padding: 0px;
    text-align: center;
    padding-right: 0px;
  }

  .headSecond {
    text-align: center;
  }

  .headFirstInner {
    width: 70%;
  }

  .green-heading {
    font-size: 30px;
    font-weight: bold;
  }

  .headRectangle {
    height: auto;
    min-height: 100vh;
    padding: 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .linesContainer {
    display: none;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 30px 0px;
    min-height: auto;
    padding: 100px 0px;
  }

  .whatIsFirst {
    text-align: center;
  }

  .whatIsSecond {
    text-align: center;
    padding-top: 100px;
  }

  .burnLogoContainer {
    display: none;
  }

  .burnTextContainer {
    text-align: center;
  }

  .burnText {
    text-align: center;
    width: 90%;
    display: inline-block;
  }

  .sportText {
    text-align: center;
    width: 90%;
    display: inline-block;
  }

  .mobileTeam {
    display: none;
  }

  .TweetBox {
    height: auto;
    margin: 15px;
    display: inline-block;
    width: 45%;
    vertical-align: top;
    text-align: left;
    cursor: pointer;
  }

  .companyes {
    flex-direction: column;
    gap: 40px;
  }

  .companyBox {
    width: 100%;
  }

  .companycontent {
    max-width: unset;
  }

  .companySection {
    overflow-x: clip;

  }

  .rounds-company-0 {
    top: -27%;
    right: 48px;
    top: -27%;
    width: 60%;
  }

  .rounds-company-1 {
    display: none;
  }

  .sportsContent .bodyText {
    font-size: 10px;
    padding-top: 8px;
  }

  .sportsContent {
    top: 20px;
  }

  .sports-mobile {
    top: 11;
    gap: 10%;
  }

  .sportsContent .gradColor {
    width: 60%;
    font-size: 13px;
  }

  .sportsContainerBoxs {
    margin-top: 55px !important;
  }

  .road-desktop {
    display: none;
  }

  .road-mobile {
    display: block;
    width: max-content;
  }

  .top-content,
  .bottomRoadMap {
    flex-direction: column;
  }

  .bottomRoadMap {
    order: -1;
    width: max-content;
    gap: 89px;
  }

  .roadMapContainer {
    flex-direction: row !important;
    justify-content: center;
  }

  .top-content {
    order: 1;
    gap: 98px;
  }

  .burnSection {
    height: max-content;
  }

  .burnRow {
    background: transparent url("./assets/globe-mobile.png") scroll no-repeat left 33%
  }
}

@media (max-width:750px) {
  .scrolled-vertical {
    display: block;
    height: 80vh;
    overflow-y: auto;
  }

  .emoryaBox,
  .emoryaBox img {
    max-width: 90px;
    height: 90px;
  }

  .emoryBox-1 div {
    left: 7px;
    top: 28px;
  }

  .emoryaBox div {
    width: 80px !important;
    height: 80px !important;
  }

  .emoryaBox span {
    font-size: 12px;
  }

  .sportsContainerBoxs {
    display: none;
  }

  .sportsMobile {
    display: block;
    padding-top: 28px;
  }

  .footer,
  .footerBottom {
    padding-bottom: 0 !important;
  }

  #road-map .swiper {
    display: none;
  }

  .roadMapContainer {
    display: flex !important;

  }

  .roadmap-image.road-mobile {
    align-self: self-start;
  }

  .top-content {
    gap: 60px;
  }
  
}


@media (max-width: 600px) {
  .roadmap-content {
    max-width: 100px;
    width: 100%;
  }

  .road-mobile {
    width: 150px;
  }

  /* .top-content,
  .bottomRoadMap {
    gap: 30px !important;
  } */

  .roadmap-image.road-mobile {
    margin-top: 0
  }

  .roadmap-content .gradColor {
    font-size: 7px !important;
    padding-bottom: 4px;
  }

  .roadmap-body {
    font-size: 7px !important;
  }

  .companySection {
    margin-block: 40px;
  }

  .emoryBox-1 div {
    top: 19px !important;
    left: 3px;
  }

  .emoryaBox,
  .emoryaBox img {
    max-width: 58px;
    height: 58px;
  }

  .emoryaBox div {
    max-width: 51px !important;
    height: 51px !important;
    /* width: 58px !important; */
  }

  .emoryBox-1 div {
    max-width: 52px !important
  }

  .emoryBoxsContainer {
    padding-inline: 0;
  }

  .emoryaBox span {
    font-size: 8px;

  }

  .modalImage img {
    width: 50%;
    margin-bottom: 20px;
  }

  .mDataContainer {
    padding: 0px 0px;
  }

  .modalBox {
    width: 90%;
    padding: 40px 15px;
  }

  .burnRow {
    background-size: 100%;
  }

  .TeamHeadingContainer {
    width: 100%;
    display: inline-block;
  }

  .headFirst {
    padding: 0px;
  }

  .headFirstInner {
    width: 100%;
  }

  .green-heading {
    font-size: 25px;
    font-weight: bold;
  }

  .whatIsSecond img {
    width: 100%;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 30px 0px;
    min-height: auto;
    padding: 50px 0px;
  }

  .linesContainer {
    display: none;
  }

  .linesContainerMobile {
    display: block;
  }

  .linesContainerMobile img {
    width: 100%;
    margin-top: 50px;
  }

  .whiteHeadingSmall {
    font-size: 13px;
  }

  .whiteHeading {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0px;
  }

  .divPad {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
  }

  .divPad .fa-circle {
    display: none;
  }

  .divPadRight .divPad {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 13px;
    font-weight: bold;
  }

  .showOnDesktop {
    display: none !important;
  }

  .tba {
    padding-left: 0px;
    font-size: 10px;
    margin: 0px 0px;
    font-weight: normal;
  }

  .BreakContainer {
    padding: 0px;
    text-align: center;
    vertical-align: top;
  }

  .daapsHeading {
    width: 100%;
  }

  .teamBox {
    background: transparent url("./assets/team-background.png") scroll no-repeat center center;
    height: 350px;
    background-size: contain;
    margin: 5px;
    display: inline-block;
    width: 100%;
    padding: 20px;
    padding-top: 2%;
    font-size: 12px;
    vertical-align: top;
    text-align: center;
    margin: 40px 0px;
  }

  .teamPic {
    width: 40%;
  }

  .mobileTeam {
    display: contents;
  }

  .teamBox .blueSubHeading {
    font-size: 11px;
    width: 70%;
    display: inline-block;
    font-weight: normal;
  }

  .TeamSection {
    margin-top: 80px;
    padding-top: 0px !important;
  }

  .showOnMobile {
    display: block;
  }

  .showOnMobile .swiper-scrollbar.swiper-scrollbar-horizontal {
    opacity: 0;
  }

  .blogBox {
    background: transparent url("./assets/blog-bck.png") scroll no-repeat center center;
    height: 450px;
    background-size: contain;
    margin: 15px;
    display: inline-block;
    width: 70%;
    padding: 15px;
    padding-top: 65px;
    font-size: 14px;
    vertical-align: top;
    text-align: left;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
  }

  .TweetBox {
    height: auto;
    margin: 15px;
    display: inline-block;
    width: 80%;
    vertical-align: top;
    text-align: left;
    cursor: pointer;
  }

  .earlyHead {
    width: 100%;
    display: inline-block;
  }

  .earlyHeadBox {
    padding-top: 0px !important;
  }


  .road-mobile {
    width: 150px;
  }

  .bottomRoadMap {
    gap: 60px;
  }

  .top-content {
    gap: 60px;
  }

  .burnRow {
    background: transparent url("./assets/globe-mini.png") scroll no-repeat left 28%
  }

  .tokenBody {
    flex-direction: column;
    flex-direction: column-reverse;
    align-items: center;
  }

  .grid-boxs {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 15px;
    row-gap: 16px;
    margin-top: 40px;
  }

  .tokenContent .body-txt {
    font-size: 12px;
  }

  .tokenContent .percent {
    font-size: 14px;
    font-weight: 500;
  }

  .footer-copy {
    color: #42E8E0A6;
    text-align: center;
  }

  .footer-copy span {
    color: #FFFFFF33;
    opacity: 1 !important;
  }

  .videoBox {
    height: 300px;
  }
  
}

@media (max-width:400px) {
  .road-mobile {
    width: 120px;
  }

  .headRectangle {
    padding-bottom: 0;
  }

  .headRectangle .green-heading {
    font-size: 16px;
    font-weight: 500;
  }

  .headRectangle .text {
    font-size: 12px;
  }

  .headSecond {
    padding-inline: 0;
  }

  .whatisEmoryaSection .blueSubHeading {
    font-size: 14px;
    font-weight: 300;
  }

  .whatisEmoryaSection .text {
    font-weight: 500;
  }

  .whatIsFirst,
  .burnTextContainer,
  .sports {
    padding-inline: 0;
  }

  .burnSection,
  #sportsSection {
    padding-top: 0;
  }

  .blueSubHeading,
  .strong-txt {
    font-size: 14px;
  }

  .burnText,
  .sportText {
    width: 100%;
  }

  .btn-green {
    font-weight: 500;
    text-transform: none;
  }

  .telegram {
    margin-top: 8px;
    display: block;
    font-weight: 300;
  }

  #sportsSection {
    padding-bottom: 0;
  }

  .TeamSection {
    margin-top: 50px;
  }

  .whiteHeading {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;

  }

  .text {
    font-size: 12px;
  }

  #freeBreakDown .whiteHeading {
    font-size: 16px;
    font-weight: 600;

  }

  .dApps {
    padding-inline: 0;
    color: #FFFFFF99;
    font-size: 12px;
  }

  .sports .TeamHeadingContainer,
  .TeamSection {
    margin-top: 0;
  }

  .partnersRow div {
    padding-inline: 0;
  }

  .TeamHeadingContainer button {
    max-width: 131px;
  }

  #contactSection {
    padding-bottom: 0;
  }

  .reachOut {
    font-size: 12px;
    font-weight: 500;
  }

  .reactBox {
    padding-inline: 0;
    font-size: 12px;
    font-weight: 500;

  }

  .headerReachOut {
    flex-direction: column-reverse;
  }

  .orderMobile {
    order: -1;
  }

  .top-content,
  .bottomRoadMap {
    gap: 40px !important;
  }

  .videoBox {
    height: 250px;
    margin-top: 50px;
  }
  

}

.footer-trade{
  max-width: 243px;
}

