body {
  margin: 0;
  background: #162B86 url("./assets/gradient.svg") scroll no-repeat left top;
  background-size: 100%;
  padding: 0px;
  margin: 0px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}